<template>
  <v-container fluid fill-height>
    <v-row fill-height>
      <div class="d-flex flex-column align-items-center">
        <div>
          <img :src="require('@/assets/images/app/mindology.png')" width="200" alt="mindology"/>
        </div>

        <div class="countdown" v-show="countdownShow">
          <svg viewBox="-50 -50 100 100" stroke-width="10">
            <circle r="45"></circle>
            <circle r="45" stroke-dasharray="282.7433388230814" stroke-dashoffset="282.7433388230814px"></circle>
          </svg>
        </div>

        <div v-show="countdownTextShow" class="countdown-text p-1 f16">لطفا منتظر بمانید...</div>

        <div v-if="statusShow" class="p-1 f16" :style="`color : ${statusColor}`">{{ status }}</div>
        <div v-if="amountShow" class="p-1 f24 fw-bold" :style="`color : ${amountColor}`">{{ amount }}</div>
        <div v-if="amountDescShow" class="p-1 f16" :style="`color : ${amountDescColor}`">{{ amountDesc }}</div>
      </div>

      <v-footer app color="transparent" class="mb-4">
        <v-container fluid>
          <v-btn class="f16" block elevation="0" rounded color="primary" large @click="openLink"
                 :disabled="btnDisabled">
            بازگشت به مایندولوژی
          </v-btn>
        </v-container>
      </v-footer>
    </v-row>
  </v-container>
</template>

<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";

moment.locale("fa", fa);
moment.loadPersian();


export default {
  data() {
    return {
      paymentStatus: undefined,
      query: undefined,

      status: '',
      statusColor: '',
      statusShow: false,

      amount: '',
      amountColor: '',
      amountShow: false,

      amountDesc: '',
      amountDescColor: '',
      amountDescShow: false,

      loadingTime: 11,
      loadingInterval: null,
      btnDisabled: true,
      countdownShow: true,
      countdownTextShow: true,

    }
  },
  methods: {
    openLink() {
      const newWindow = window.open(this.url, "myWindow", 'width=100,height=100');
      newWindow.onblur = newWindow.close();
      this.$router.push({path: '/c/profile/wallet'})
    },
    loading() {
      this.btnDisabled = true;
      if (this.loadingTime > 0) {
        this.loadingTime--;
      } else {
        clearInterval(this.loadingInterval);
        this.btnDisabled = false;
        this.countdownShow = false;
        this.countdownTextShow = false;
        if (this.paymentStatus == "SUCCESS") {
          this.status = "پرداخت موفقیت آمیز بود";
          this.statusColor = "#424242";
          this.statusShow = true;

          this.amount = this.$store.getters.paymentAmount + " ریال"
          this.amountColor = "#009933";
          this.amountShow = true;

          this.amountDesc = "به اعتبار شما اضافه شد";
          this.amountDescColor = "#009933";
          this.amountDescShow = true;
        } else if (this.paymentStatus == "FAILED") {
          this.status = "متاسفانه پرداخت ناموفق بود";
          this.statusColor = "#cc0000";
          this.statusShow = true;
          this.amountShow = false;
          this.amountDescShow = false;
        }
      }
    },
    callVerifyPayment() {
      this.$router.replace({query: {}});
      apiService.verifyPayment(this.query)
          .then((response) => {
            this.paymentStatus = response.data.status;
            this.$store.commit('update_payment_status', response.data.status);
          })
          .catch(error => {
            this.paymentStatus = "FAILED";
            this.status = "متاسفانه پرداخت ناموفق بود";
            this.$store.commit('update_payment_status', "FAILED");
          });
    }
  },
  mounted() {
    this.loadingInterval = setInterval(() => {
      this.loading()
    }, 1000);

    this.loading();
    this.query = this.$route.query;
    this.callVerifyPayment();
  },
}
</script>
<style scoped>
.countdown {
  display: grid;
  margin: 1em auto;
  width: 10em;
  height: 10em;
}

.countdown::after {
  grid-column: 1;
  grid-row: 1;
  place-self: center;
  font: 3em/2 ubuntu mono, consolas, monaco, monospace;
  animation: num 10s steps(1);
  content: counter(s);
}

@keyframes num {
  0% {
    counter-reset: s 10;
  }
  10% {
    counter-reset: s 9;
  }
  20% {
    counter-reset: s 8;
  }
  30% {
    counter-reset: s 7;
  }
  40% {
    counter-reset: s 6;
  }
  50% {
    counter-reset: s 5;
  }
  60% {
    counter-reset: s 4;
  }
  70% {
    counter-reset: s 3;
  }
  80% {
    counter-reset: s 2;
  }
  90% {
    counter-reset: s 1;
  }
  100% {
    counter-reset: s 0;
  }
  /*55% {
   counter-reset: s 9;
 }
 60% {
   counter-reset: s 8;
 }
 65% {
   counter-reset: s 7;
 }
 70% {
   counter-reset: s 6;
 }
 75% {
   counter-reset: s 5;
 }
 80% {
   counter-reset: s 4;
 }
 85% {
   counter-reset: s 3;
 }
 90% {
   counter-reset: s 2;
 }
 95% {
   counter-reset: s 1;
 }
 100% {
   counter-reset: s 0;
 } */
}

svg {
  grid-column: 1;
  grid-row: 1;
}

[r] {
  fill: none;
  stroke: silver;
}

[r] + [r] {
  transform: rotate(-90deg);
  stroke-linecap: round;
  stroke: #8A9B0F;
  animation: arc 10s linear;
  animation-name: arc, col;
}

@keyframes arc {
  0% {
    stroke-dashoffset: 0px;
  }
}

@keyframes col {
  0% {
    stroke: #8A9B0F;
  }
  25% {
    stroke: #F8CA00;
  }
  50% {
    stroke: #E97F02;
  }
  75% {
    stroke: #BD1550;
  }
  100% {
    stroke: #490A3D;
  }
}
</style>