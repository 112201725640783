var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-row",
        { attrs: { "fill-height": "" } },
        [
          _c("div", { staticClass: "d-flex flex-column align-items-center" }, [
            _c("div", [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/app/mindology.png"),
                  width: "200",
                  alt: "mindology",
                },
              }),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.countdownShow,
                    expression: "countdownShow",
                  },
                ],
                staticClass: "countdown",
              },
              [
                _c(
                  "svg",
                  {
                    attrs: { viewBox: "-50 -50 100 100", "stroke-width": "10" },
                  },
                  [
                    _c("circle", { attrs: { r: "45" } }),
                    _c("circle", {
                      attrs: {
                        r: "45",
                        "stroke-dasharray": "282.7433388230814",
                        "stroke-dashoffset": "282.7433388230814px",
                      },
                    }),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.countdownTextShow,
                    expression: "countdownTextShow",
                  },
                ],
                staticClass: "countdown-text p-1 f16",
              },
              [_vm._v("لطفا منتظر بمانید...")]
            ),
            _vm.statusShow
              ? _c(
                  "div",
                  {
                    staticClass: "p-1 f16",
                    style: `color : ${_vm.statusColor}`,
                  },
                  [_vm._v(_vm._s(_vm.status))]
                )
              : _vm._e(),
            _vm.amountShow
              ? _c(
                  "div",
                  {
                    staticClass: "p-1 f24 fw-bold",
                    style: `color : ${_vm.amountColor}`,
                  },
                  [_vm._v(_vm._s(_vm.amount))]
                )
              : _vm._e(),
            _vm.amountDescShow
              ? _c(
                  "div",
                  {
                    staticClass: "p-1 f16",
                    style: `color : ${_vm.amountDescColor}`,
                  },
                  [_vm._v(_vm._s(_vm.amountDesc))]
                )
              : _vm._e(),
          ]),
          _c(
            "v-footer",
            { staticClass: "mb-4", attrs: { app: "", color: "transparent" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "f16",
                      attrs: {
                        block: "",
                        elevation: "0",
                        rounded: "",
                        color: "primary",
                        large: "",
                        disabled: _vm.btnDisabled,
                      },
                      on: { click: _vm.openLink },
                    },
                    [_vm._v(" بازگشت به مایندولوژی ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }